@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  height: 100%;

  min-height: 100vh;

  display: flex;
  flex-direction: column;

  > main {
    flex: 1;
  }
}

.keen-slider.books-carousel {
  overflow: visible !important;

  .keen-slider__slide {
    overflow: visible !important;
  }
}
